<template>
  <div>
    <div class="relative p-[25px] border border-solid border-desired-dawn rounded-[6px] bg-white mt-[21px]">
      <span class="absolute ph ph-calendar-dots right-[16px] top-[16px] text-primary text-[20px]"></span>
      <div class="grid md:grid-cols-4 grid-cols-1">
        <div>
          <p class="text-[16px] text-industrial-revolution underline">{{ $t('Last Statement') }}</p>
          <p class="mt-[6px] text-industrial-revolution">
            <span class="md:text-[24px] text-[20px] text-young-night">{{ statements[0] && statements[0].created ? $t(formatDate(statements[0].created).split(",")[0]) : '' }}</span>
            <span class="text-[14px]">({{ statements[0] && statements[0].created ? formatDate(statements[0].created).split(" ")[1] : '' }})</span>
          </p>
        </div>
        <div class="md:mt-0 mt-[23px]">
          <p class="text-[16px] text-industrial-revolution">{{ $t('Transfer Date') }}</p>
          <p class="text-industrial-revolution mt-[6px]"> 
            <span class="md:text-[24px] text-[20px] text-young-night">{{ $t(daysDicReverse[nextStatment]) }}</span> 
            <span class="text-[14px]">({{ nextStatmentFormatted }})</span>
          </p>
        </div>
        <div class="md:mt-0 mt-[23px]">
          <p class="text-[16px] text-industrial-revolution">{{ $t('Cash on the way') }}</p>
          <p class="md:text-[24px] text-[20px] text-young-night mt-[6px]">{{ $t('EGP') }} {{ financials.cash_on_the_way.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
        </div>
        <div class="md:mt-0 mt-[23px]">
          <p class="text-[16px] text-industrial-revolution">{{ $t('Next Statment') }}</p>
          <p class="text-industrial-revolution mt-[6px]"> 
            <span class="md:text-[24px] text-[20px] text-young-night">{{ $t(daysDicReverse[nextTransferDay]) }}</span> 
            <span class="text-[14px]">({{ nextTransferDayFormatted }})</span>
          </p>
        </div>
      </div>
    </div>
    <div class="grid md:grid-cols-2 grid-cols-1 gap-[16px] mt-[16px] relative">
      <div class="relative p-[25px] border border-solid border-desired-dawn rounded-[6px] bg-white">
        <p class="md:text-[18px] text-[16px] text-young-night">{{ $t('Available Cash Balance') }}</p>
        <p class="md:text-[32px] text-[24px] text-young-night">{{ availableCashBalance }}</p>
        <span @click="breakdownModal = true" class="absolute underline top-[35px] right-[22px] text-primary cursor-pointer">{{ $t('Breakdown') }}</span>
        <div class="mt-[52px] items-center col-span-2">
          <div @click="addInstantPayout" id="instant-payout-btn" type="filled" class="cursor-pointer bg-primary rounded-[4px] text-white w-full md:text-[16px] text-[14px] py-[10px] px[94px] text-center">
            {{$t('Instant Payout')}}
          </div>
        </div>
      </div>
      <div class="relative p-[25px] border border-solid border-desired-dawn rounded-[6px] bg-white">
        <p class="md:text-[18px] text-[16px] text-young-night">{{ $t('Wallet Balance') }}</p>
        <p class="md:text-[32px] text-[24px] text-young-night">{{ merchant.quickbooks_wallet_balance ? merchant.quickbooks_wallet_balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : '0.0' }}</p>
        <div class="mt-[52px] grid grid-cols-2 gap-[10px]">
          <div @click="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant' ? topUpModal = false : checkAccountType()" type="filled" :class="merchant.is_self_signup && merchant.name === 'Entrepreneur Merchant' ? 'disabled-btn' : 'active-btn' " class="cursor-pointer bg-primary rounded-[4px] text-white w-full md:text-[16px] text-[14px] py-[10px] px[94px] text-center">
            {{$t('Deposit')}}
          </div>
          <div @click="withdrawalRequestModal = true" class="cursor-pointer bg-polished-metal rounded-[4px] text-white w-full md:text-[16px] text-[14px] py-[10px] px[94px] text-center" type="filled">
            {{$t('withdrawButton')}}
          </div>
        </div>
      </div>
    </div>
    <div class="mt-[16px]">
      <div class="rounded-t-[6px] bg-white py-[15px] px-[24px] border border-solid border-gainsboro md:flex justify-between hidden mb-[-4px] relative z-[999]">
        <span class="text-young-night text-[16px]">{{ $t('Recent Transactions') }}</span>
        <!-- <span class="underline text-[14px] text-primary cursor-pointer" @click="$router.push({ query: {tab:'transactions'}})">{{ $t('View More') }}</span> -->
      </div>
      <shipblu-table
        orders
        :sst="true"
        :data="transactions"
        :tableLoader="tableLoader"
        :hideHeader="true"
      >
        <template slot="thead">
          <shipblu-th class="pl-[24px]">{{$t('Transaction ID')}}</shipblu-th>
          <shipblu-th>{{$t('Amount')}}</shipblu-th>
          <shipblu-th>{{$t('Type')}}</shipblu-th>
          <shipblu-th>{{$t('Date')}}</shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr])}`">
            <shipblu-td class="order-0" :data="data[indextr].name">
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Transaction ID')}}</p>
              <span class="sm:pl-[12px]">{{ data[indextr].id }}</span>
            </shipblu-td>

            <shipblu-td class="order-4" :data="data[indextr].amount">
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Amount')}}</p>
              {{ data[indextr].amount }}
            </shipblu-td>

            <shipblu-td class="order-5" :data="data[indextr].type">
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Type')}}</p>
              {{ data[indextr].type }}
            </shipblu-td>

            <shipblu-td class="order-5" :data="data[indextr].Date">
              <p class="sm:hidden block text-secondary text-sm mb-2">{{$t('Date')}}</p>
              {{ new Date(data[indextr].created).toLocaleDateString('fr-CA') }}
            </shipblu-td>

          </shipblu-tr>
        </template>
      </shipblu-table>
    </div>
    <top-up-method v-if="topUpModal" @topUpModal="topUpModal = $event" />
    <breakdown-modal :breakdownModal="breakdownModal" @breakdownModal="breakdownModal = $event" :availableCashBalance="availableCashBalance" :financials="financials"></breakdown-modal>
    <withdrawal-request-modal :withdrawalRequestModal="withdrawalRequestModal" @withdrawalRequestModal="withdrawalRequestModal = $event"></withdrawal-request-modal>
    <instant-payout-modal :instant="instant" v-if="instantPayoutModal" @instantPayoutModal="instantPayoutModal = $event" />
  </div>
</template>

<script>
import {sendRequest} from './../../http/axios/requestHelper.js'
import common  from '@/assets/utils/common'
import i18nData from './../../i18n/i18nData.js'
import TopUpMethod from './components/TopUpMethod.vue'
import BreakdownModal from '../components/BreakdownModal.vue'
import WithdrawalRequestModal from '../components/WithdrawalRequestModal.vue'
import ShipbluTable from './../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from './../../layouts/components/ShipBluTr.vue'
import ShipbluTh from './../../layouts/components/ShipBluTh.vue'
import ShipbluTd from './../../layouts/components/ShipBluTd.vue'
import InstantPayoutModal from './components/InstantPayoutModal.vue'

export default {
  data () {
    return {
      statements: [],
      billingDays: [],
      nextStatment: '',
      nextStatmentFormatted: '',
      nextTransferDay: '',
      nextTransferDayFormatted: '',
      daysDicReverse: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
      },
      bankTransferDays: [],
      transferCoversprev:[],
      daysDic: {},
      financials: {
        cash_collection_balance: 0.0,
        service_fees_due: 0.0,
        refunds_in_progress: 0.0,
        cash_on_the_way: 0.0,
        amounts_on_hold: 0
      },
      merchant:[],
      topUpModal: false,
      breakdownModal: false,
      withdrawalRequestModal: false,
      tableLoader: true,
      transactions: [],
      instantPayoutModal: false,
      instant: {
        available_cod: 0.0,
        service_fees_due: 0.0,
        on_hold_amount: 0.0,
        instant_payout_fees: 0.0,
        transfer_fees: 0.0,
        payout_amount: 0.0
      },
      routeQuery: {}
    }
  },
  computed: {
    availableCashBalance () {
      return (
        Number(this.financials.cash_collection_balance) - 
        Number(this.financials.service_fees_due) - 
        Number(this.financials.amounts_on_hold) - 
        Number(this.financials.refunds_in_progress || 0)
      ).toLocaleString('en', { 
        minimumFractionDigits: 2, 
        maximumFractionDigits: 2 
      })
    }
  },
  methods: {
    loadStatements () {
      this.tableLoader = true
      sendRequest(true, false, this, `api/v1/billing/statements/?merchant=${this.merchantID}&status=ready`, 'get', null, true, 
        (response) => {
          this.statements = response.data.results
        }
      )
    },
    viewBankDeposit (data) {
      this.bankDepositModal = true
      this.bankDeposits = data.bank_deposits
    },
    downloadStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/excel/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    loadDay () {
      this.daysLoader = true
      sendRequest(true, false, this, 'api/v1/subscriptions/pickup-days/', 'get', null, true,
        (response) => {
          response.data.map(item => { this.daysDic[item.id] = item.day })
          this.showNextDayStatement()
        }
      )
    },
    transferDays () {
      for (let i = 0; i < this.bankTransferDays.length; i++) {
        const statmentDay = i
        this.bankTransferDays[i] = this.bankTransferDays[i] + 1
        // make friday sunday
        if (this.bankTransferDays[i] === 7) this.bankTransferDays[i] = 2 
        let previousStatmentDay = i - 1
        if (previousStatmentDay < 0) previousStatmentDay = this.bankTransferDays.length - 1
        let transferWindow = (this.billingDays[statmentDay] - this.billingDays[previousStatmentDay] + 6) % 6
        if (transferWindow === 0) transferWindow = 6
        const prevWeek = []
        const thisWeek = []
        for (let j = 0; j < transferWindow; j++) {
          let cover = this.billingDays[statmentDay] - j - 1
          let isPrevWeek = false
          if (cover <= 0) {
            cover += 6
            isPrevWeek = true
          }
          if (this.bankTransferDays[statmentDay] > cover && !isPrevWeek) {
            thisWeek.push(cover)
          } else {
            prevWeek.push(cover)
          }
        }
        this.transferCoversprev.push({
          prevWeek: prevWeek.sort(),
          thisWeek: thisWeek.sort(),
          day: this.bankTransferDays[i]
        })
      }    
    },
    showNextDayStatement () {
      const date = new Date()
      const today = date.getDay()
      this.billingDays.sort()
      const realDaysArray = []
      this.billingDays.forEach((item) => {
        const realItem = item === 1 ? 6 : item - 2
        realDaysArray.push(realItem)
      })
      let indexPaymentDayIndex = 0
      let isLastDay = false
      for (let i = realDaysArray.length - 1; i >= 0; i--) {
        if (today < realDaysArray[i]) {
          indexPaymentDayIndex = realDaysArray[i]
          this.nextStatment = realDaysArray[i]
          isLastDay = true
        } else if (!isLastDay) {
          indexPaymentDayIndex = realDaysArray[0]
          this.nextStatment = realDaysArray[0] 
        }
      }
      let dayDelta = indexPaymentDayIndex - today
      dayDelta = (dayDelta + 7) % 7
      this.nextStatmentFormatted = new Date(date)
      this.nextStatmentFormatted.setDate(this.nextStatmentFormatted.getDate() + dayDelta)
      this.nextStatmentFormatted = this.changeFormate(this.nextStatmentFormatted)
      this.showNextDayStatementTransfer()
    },
    showNextDayStatementTransfer () {
      const date = new Date()
      const today = date.getDay()
      const paymentdays = []
      this.transferCoversprev.forEach((item, index) => {
        paymentdays[index] = item.day
      })
      paymentdays.sort()
      const realDaysArray = []
      paymentdays.forEach((item) => {
        const realItem = item === 1 ? 6 : item - 2
        realDaysArray.push(realItem)
      })
      let indexPaymentDayIndex = 0
      let isLastDay = false
      for (let i = realDaysArray.length - 1; i >= 0; i--) {
        if (today < realDaysArray[i]) {
          indexPaymentDayIndex = realDaysArray[i]
          this.nextTransferDay = realDaysArray[i]
          isLastDay = true
        } else if (!isLastDay) {
          indexPaymentDayIndex = realDaysArray[0]
          this.nextTransferDay = realDaysArray[0]
        }
      }
      let dayDelta = indexPaymentDayIndex - today
      dayDelta = (dayDelta + 7) % 7
      this.nextTransferDayFormatted = new Date(date)
      this.nextTransferDayFormatted.setDate(this.nextTransferDayFormatted.getDate() + dayDelta)
      this.nextTransferDayFormatted = this.changeFormate(this.nextTransferDayFormatted)
      this.daysLoader = false
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    changeFormate (oldFromat) {
      const today = oldFromat
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1
      let dd = today.getDate()
      if (dd < 10) dd = `0${dd}`
      if (mm < 10) mm = `0${mm}`
      return `${dd}/${mm}/${yyyy}`
    },
    formatDate (isoString) {
      if (!isoString) return ''
      const date = new Date(isoString)
      const options = { weekday: 'long' } // Get the full day name
      const dayName = new Intl.DateTimeFormat('en-GB', options).format(date)
      const day = date.getDate()
      const month = date.getMonth() + 1 // Months are 0-based
      const year = date.getFullYear()
      return `${dayName}, ${day}/${month}/${year}`
    },
    loadFinancials () {
      this.tableLoader = true
      sendRequest(true, false, this, 'api/v1/analytics/merchant/dashboard/financial-center/', 'get', null, true, 
        (response) => {
          this.financials = response.data
          this.tableLoader = false
        }
      )
    },
    isBillingDay () {
      const now = new Date()
      // Get the current day of the week (Saturday = 1, Sunday = 2, ..., Thursday = 6, Friday = 7)
      const currentDay = new Intl.DateTimeFormat('en-US', {
        timeZone: 'Africa/Cairo',
        weekday: 'long'
      }).format(now)
      const dayMap = {
        Saturday: 1,
        Sunday: 2,
        Monday: 3,
        Tuesday: 4,
        Wednesday: 5,
        Thursday: 6,
        Friday: 7
      }
      const todayIndex = dayMap[currentDay]
      return this.merchant.billing_days ? this.merchant.billing_days.includes(todayIndex) : ''
    },
    checkAccountType () {
      if (this.merchant.has_tax_profile) {
        this.topUpModal = true
      } else {
        this.$vs.dialog({
          color: 'danger',
          title: i18nData[this.$i18n.locale]['Oops!'],
          text: i18nData[this.$i18n.locale]['You don\'t have a tax profile to perform this action!'],
          acceptText: i18nData[this.$i18n.locale]['Choose Account type'],
          accept: this.goToAddTaxProfile
        })
      }
    },    
    getEgyptTime () {
      const egyptTimeZone = 'Africa/Cairo'
      const now = new Date()
      const daysOptions = { timeZone: egyptTimeZone, weekday: 'long' }
      const egyptDay = now.toLocaleDateString('en-US', daysOptions)

      const hoursOptions = { timeZone: egyptTimeZone, hour: '2-digit', minute: '2-digit', hour12: false }
      const egyptTime = now.toLocaleTimeString('en-US', hoursOptions)
      return [egyptTime, egyptDay]
    },
    addInstantPayout () {
      if ((Number(this.getEgyptTime()[0].split(':')[0]) >= 7 && Number(this.getEgyptTime()[0].split(':')[0]) < 12) && this.getEgyptTime()[1] !== 'Friday' && this.getEgyptTime()[1] !== 'Saturday') {
        this.$vs.loading({
          background: this.backgroundLoading,
          color: this.colorLoading,
          container: '#instant-payout-btn',
          scale: 0.45
        })
        sendRequest(true, false, this, 'api/v1/instant/payouts/?dry_run=True', 'get', null, true,
          (response) => {
            this.instant = response.data
            this.instantPayoutModal = true
            this.$vs.loading.close('#instant-payout-btn > .con-vs-loading')
          }, (error) => {
            common.notifyResponseError(this, error)
            this.$vs.loading.close('#instant-payout-btn > .con-vs-loading')
          }
        )
      } else {
        this.$vs.notify({
          color:'warning',
          title: i18nData[this.$i18n.locale]['Warning'],
          text: 'Instant Payout should be requested before 12 PM',
          position: 'top-center'
        })
      }
    },
    loadTransactionsHistory () {
      sendRequest(true, false, this, 'api/v1/accounting/merchant-financial-events/?limit=3', 'get', null, true, 
        (response) => {
          this.transactions = response.data.results
          this.tableLoader = false
        }, () => {}
      )
    }
  },
  components: {
    TopUpMethod,
    BreakdownModal,
    WithdrawalRequestModal,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    InstantPayoutModal
  },
  created () {
    this.routeQuery = { ...this.$route.query };
    ['page', 'subTab', 'filter'].forEach(key => delete this.routeQuery[key])
    this.$router.push({ query: { ...this.routeQuery } }).catch(() => {})
    const merchantData = common.checkMerchantInLocalStorage(this)
    let user = {}
    merchantData.then(results => {
      this.merchant = results.merchantData
      user = results.merchantData
      this.merchantID = user.id
      this.loadStatements()
      this.billingDays = user.billing_days.sort()
      this.bankTransferDays = [...this.billingDays]
      this.loadDay()
      this.transferDays()
      this.loadFinancials()
      this.loadTransactionsHistory()
    })
  }
}
</script>