<template>
  <div id="page-user-view" class="sm:mt-[33px] mt-[24px]">
    <div class="flex">
      <div v-for="tab in tabs" :key="tab.index" :class="$route.query.subTab === tab.value ? 'border-solid border mb-[-1px] bg-layout border-gainsboro border-b-0 text-primary' : 'text-berwick-berry'" class="py-[10px] rounded-t-[4px] leading-[20px] sm:px-[24px] sm:text-[16px] text-[14px] cursor-pointer sm:font-medium font-normal text-center sm:w-auto w-1/2" @click="changeTabFilter(tab.value)">
        <p>{{$t(tab.name)}}</p>
      </div>
    </div>
    <!-- Statements & Invoices Tabs -->
    <instant-payout v-if="activeTab === 'instant-payout'" class="border-solid border border-gainsboro sm:p-[18px] p-[16px]" />

    <div v-else class="border-solid border border-gainsboro sm:p-[18px] p-[16px]">
      <shipblu-table
        :sst="true"
        oredrs
        :data="statements"
        :tableLoader="tableLoader"
        search
        @search="handleSearch"
        @keyup.enter.native="handleSearch"
      >
        <template slot="header" class="flex lg:items-center bg-red">
          <div class="sm:w-auto w-full md:mt-0 flex gap-2 mr-auto">
            <date-filter :isShowCriteria="false" filterLabel="date" class="w-full"  @searchDate="handleSearchDate" ref="filterDateModal" />
          </div>
        </template>
        <template slot="thead">
          <shipblu-th class="text-young-night">{{$t('Statement Date')}}</shipblu-th>
          <shipblu-th class="text-young-night">{{$t('Statement Number')}}</shipblu-th>
          <shipblu-th class="text-young-night">{{$t('Cash Collection Balance')}}</shipblu-th>
          <shipblu-th class="text-young-night">{{$t('Balance Due')}}</shipblu-th>
          <shipblu-th class="text-young-night">{{$t('Expected Payout')}}</shipblu-th>
          <shipblu-th class="text-young-night" v-if="$store.state.AppActiveUser.userRole !== 'merchant'">{{$t('Status')}}</shipblu-th>
          <shipblu-th class="text-young-night" v-if="$store.state.AppActiveUser.userRole === 'merchant'">{{$t('Download Statement')}}</shipblu-th>
          <shipblu-th class="text-young-night" v-else>{{$t('Statement PDF')}}</shipblu-th>
          <shipblu-th class="text-young-night">
            <div class="flex justify-center">
              {{$t('Transfer Details')}}
              <vx-tooltip :text="`${$t('The Transfer details will be available once the amount is successfully transferred to your account')}`">
                <feather-icon class="ml-2 cursor-pointer" icon="AlertCircleIcon" svgClasses="w-4 h-4 text-grey"></feather-icon>
              </vx-tooltip>
            </div>
          </shipblu-th>
        </template>

        <template slot-scope="{ data }">
          <shipblu-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data" :color="`${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
            <shipblu-td class="order-4" :data="data[indextr].id" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Statement Date')}}</p>
              <span class="text-young-night sm:text-[14px] text-[12px]">{{ new Date(data[indextr].created).toLocaleDateString('fr-CA')}}</span>
            </shipblu-td>

            <shipblu-td class="order-4" :data="data[indextr].number">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Statement Number')}}</p>
              <p class="sm:text-young-night text-fluorescent-red sm:text-[14px] text-[12px]">{{ data[indextr].number }}</p>
            </shipblu-td>

            <shipblu-td class="order-6" :data="data[indextr].net_cod_due">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Cash Collection Balance')}}</p>
              <p class="text-young-night sm:text-[14px] text-[12px]">{{ data[indextr].v2 ? Number(data[indextr].v2.cash_closing_balance).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
            </shipblu-td>

            <shipblu-td class="order-6" :data="data[indextr].net_service_fees_due">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Balance Due')}}</p>
              <p class="text-young-night sm:text-[14px] text-[12px]">{{ data[indextr].v2 ? Number(data[indextr].v2.balance_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_service_fees_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
            </shipblu-td>

            <shipblu-td class="order-5" :data="data[indextr].net_cod_due">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Expected Payout')}}</p>
              <p class="text-young-night sm:text-[14px] text-[12px]">{{ data[indextr].v2 ? Number(data[indextr].v2.payout).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : Number(data[indextr].net_cod_due).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</p>
            </shipblu-td>

            <shipblu-td class="order-8" v-if="$store.state.AppActiveUser.userRole !== 'merchant'" :data="data[indextr].status" :style="`color: ${getStyle(data[indextr].v2 ? data[indextr].v2 : data[indextr])}`">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Status')}}</p>
              <div class="m-auto flex items-center justify-center font-semibold">
                <div><span class="status-icon mr-1"></span></div>
                <span class="capitalize text-young-night sm:text-[14px] text-[12px]">{{ $t(getStatusLabel(data[indextr].v2 ? data[indextr].v2 : data[indextr])) }}</span>
              </div>
            </shipblu-td>

            <shipblu-td class="order-11 md:block hidden" :data="data[indextr].pdf_link" v-if="$store.state.AppActiveUser.userRole === 'merchant'">
              <a @click="downloadStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('PDF')}}</a> / 
              <a @click="downloadExcelStatement(data[indextr])" target="_blank" class="hover:underline">{{$t('Excel')}}</a> 
            </shipblu-td>

            <shipblu-td class="order-12 md:hidden block" :data="data[indextr].pdf_link" v-if="$store.state.AppActiveUser.userRole === 'merchant'">
              <div class="bg-primary justify-center items-center rounded-[4px] px-[16px] py-[8px] w-full cursor-pointer">
                <a @click="downloadExcelStatement(data[indextr])" target="_blank" class="text-white whitespace-nowrap text-sm font-semibold leading-[20px] text-center">{{$t('Download') + ' Excel'}}</a> 
              </div>
            </shipblu-td>

            <shipblu-td class="order-13 md:hidden block" :data="data[indextr].pdf_link" v-if="$store.state.AppActiveUser.userRole === 'merchant'">
              <div class="bg-polished-metal flex justify-center items-center rounded-[4px] px-[16px] py-[8px] w-full cursor-pointer">
                <a @click="downloadStatement(data[indextr])" target="_blank" class="text-white whitespace-nowrap text-sm font-semibold leading-[20px]">{{$t('Download') + ' PDF'}}</a> 
              </div>
            </shipblu-td>

            <shipblu-td class="order-10" :data="data[indextr].pdf_link" v-if="$store.state.AppActiveUser.userRole !== 'merchant'">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Download Statement')}}</p>
              <a @click="downloadStatement(data[indextr])" target="_blank">{{$t('Download')}}</a>
            </shipblu-td>
            
            <shipblu-td class="order-9">
              <p class="sm:hidden block text-industrial-revolution text-[12px] mb-[6px]">{{$t('Transfer Details')}}</p>
              <div class="flex items-center sm:justify-center">
                <p class="flex" @click="viewBankDeposit(data[indextr])" :class="data[indextr].bank_deposits.length === 0 ? 'inactive-link text-berwick-berry' : 'text-primary hover:underline'">
                  <span class="sm:text-[14px] text-[12px]">{{$t('view more')}}</span>
                </p>
              </div>
            </shipblu-td>
          </shipblu-tr>
        </template>
      </shipblu-table>
      <shipblu-pagination :maximumItems="maximumItems" :totalRows="totalRows" :currentPage="currentPage" @currentPage="currentPage = $event" @changePageSize="changePageSize" />     
    </div>
    
    <bank-deposits :bankDeposits="bankDeposits" :bankDepositModal="bankDepositModal" @bankDepositModal="bankDepositModal = $event"></bank-deposits>
  </div>
</template>

<script>
import {sendRequest} from '../../http/axios/requestHelper.js'
import BankDeposits from './BankDeposits.vue'
import common  from '@/assets/utils/common'
import ShipbluTable from '../../layouts/components/NewShipBluTable.vue'
import ShipbluTr from '../../layouts/components/ShipBluTr.vue'
import ShipbluTh from '../../layouts/components/ShipBluTh.vue'
import ShipbluTd from '../../layouts/components/ShipBluTd.vue'
import LatestStatment from './components/LatestStatment.vue'
import ShipbluCard from '../../layouts/components/ShipbluCard.vue'
import DateFilter from './components/DateFilter.vue'
import ShipbluPagination from '../../layouts/components/ShipbluPagination.vue'
import InstantPayout from './InstantPayout.vue'

export default {
  data () {
    return {
      maximumItems: localStorage.getItem('maxItems') ? Number(localStorage.getItem('maxItems')) : process.env.VUE_APP_MAXIMUM_ITEMS_TABLE,
      statements: [],
      billingDays: [],
      merchant:[],
      nextStatment: '',
      nextStatmentFormatted: '',
      nextTransferDay: '',
      nextTransferDayFormatted: '',
      daysDicReverse: {
        0: 'Sunday',
        1: 'Monday',
        2: 'Tuesday',
        3: 'Wednesday',
        4: 'Thursday',
        5: 'Friday',
        6: 'Saturday'
      },
      bankTransferDays: [],
      transferCoversprev: [],
      merchantID: '',
      totalRows: 0,
      offset: 0,
      currentPage: (this.$route.query.page &&  Number(this.$route.query.page) > 0) ? Number(this.$route.query.page) : 1,
      bankDepositModal: false,
      bankDeposits: [],
      file_URl: '',
      daysDic: {},
      tableLoader: false,
      summaryCashamount: '',
      daysLoader: false,
      activeTab: 'statements',
      latestStatment: '',
      financials: {
        cash_collection_balance: 0.0,
        service_fees_due: 0.0,
        refunds_in_progress: 0.0,
        cash_on_the_way: 0.0,
        amounts_on_hold: 0
      },
      searchVal: '',
      searchedValue: ' ',
      searchInProgress: false,
      tabs:[
        {
          name: 'Statements',
          value: 'statements'
        },
        {
          name: 'Instant Payout',
          value: 'instant-payout'
        }
      ],
      fromDate: '',
      toDate: ''
    }
  },
  watch: {
    currentPage () {
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.$router.push({
        query: {
          ...this.$route.query,
          tab: this.$route.query.tab,
          page: this.currentPage
        }
      }).catch(() => {})
      this.loadStatements()
    },
    'activeTab' (val) {
      this.currentPage = 1
      this.$router.push({
        query: {
          ...this.$route.query,
          page: this.currentPage,
          subTab: val
        }
      }).catch(() => {})
    }
  },
  methods: {
    changeTabFilter (tab) {
      this.activeTab = tab
      this.$router.push({
        query: {
          ...this.$route.query,
          subTab: tab,
          page: 1
        }
      }).catch(() => {})
      
    },
    changePageSize (val) {
      localStorage.setItem('maxItems', val)
      this.maximumItems = val
      this.currentPage = 1
      this.loadStatements()
    },
    getStyle (data) {
      return common.getOrderStatusColor(data.status)
    },
    getStatusLabel (order) {
      return common.getStatusLabel(order, this.$store.state.AppActiveUser.userRole)
    },
    getOrderStatusColor (order) {
      return common.getOrderStatusColor(order.status)
    },
    loadStatements () {
      this.tableLoader = true
      const query = `&offset=${this.offset}&limit=${this.maximumItems}`
      sendRequest(true, false, this, `api/v1/billing/statements/?merchant=${this.merchantID}&status=ready${query}&search=${this.searchVal}&created_before=${this.toDate ? this.toDate : ''}&created_after=${this.fromDate ? this.fromDate : ''}`, 'get', null, true, 
        (response) => {
          this.statements = response.data.results
          if (this.offset === 0) {
            this.latestStatment = this.statements[0]
          }
          this.searchedValue = this.searchVal
          this.totalRows = response.data.count
          this.tableLoader = false
        }
      )
    },
    viewBankDeposit (data) {
      this.bankDepositModal = true
      this.bankDeposits = data.bank_deposits
    },
    downloadStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/pdf/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    downloadExcelStatement (data) {
      sendRequest(false, false, this, `api/v1/billing/statements/${data.id}/excel/`, 'get', null, true, 
        (response) => {
          this.file_URl = response.data.file_url_v2 !== '' ? response.data.file_url_v2 : response.data.file_url
          window.open(this.file_URl, '_blank')
        }
      )
    },
    loadDay () {
      this.daysLoader = true
      sendRequest(true, false, this, 'api/v1/subscriptions/pickup-days/', 'get', null, true,
        (response) => {
          response.data.map(item => { this.daysDic[item.id] = item.day })
          this.showNextDayStatement()
        }
      )
    },
    transferDays () {
      for (let i = 0; i < this.bankTransferDays.length; i++) {
        const statmentDay = i
        this.bankTransferDays[i] = this.bankTransferDays[i] + 1
        // make friday sunday
        if (this.bankTransferDays[i] === 7) this.bankTransferDays[i] = 2 
        let previousStatmentDay = i - 1
        if (previousStatmentDay < 0) previousStatmentDay = this.bankTransferDays.length - 1
        let transferWindow = (this.billingDays[statmentDay] - this.billingDays[previousStatmentDay] + 6) % 6
        if (transferWindow === 0) transferWindow = 6
        const prevWeek = []
        const thisWeek = []
        for (let j = 0; j < transferWindow; j++) {
          let cover = this.billingDays[statmentDay] - j - 1
          let isPrevWeek = false
          if (cover <= 0) {
            cover += 6
            isPrevWeek = true
          }
          if (this.bankTransferDays[statmentDay] > cover && !isPrevWeek) {
            thisWeek.push(cover)
          } else {
            prevWeek.push(cover)
          }
        }
        this.transferCoversprev.push({
          prevWeek: prevWeek.sort(),
          thisWeek: thisWeek.sort(),
          day: this.bankTransferDays[i]
        })
      }    
    },
    showNextDayStatement () {
      const date = new Date()
      const today = date.getDay()
      this.billingDays.sort()
      const realDaysArray = []
      this.billingDays.forEach((item) => {
        const realItem = item === 1 ? 6 : item - 2
        realDaysArray.push(realItem)
      })
      let indexPaymentDayIndex = 0
      let isLastDay = false
      for (let i = realDaysArray.length - 1; i >= 0; i--) {
        if (today < realDaysArray[i]) {
          indexPaymentDayIndex = realDaysArray[i]
          this.nextStatment = realDaysArray[i]
          isLastDay = true
        } else if (!isLastDay) {
          indexPaymentDayIndex = realDaysArray[0]
          this.nextStatment = realDaysArray[0] 
        }
      }
      let dayDelta = indexPaymentDayIndex - today
      dayDelta = (dayDelta + 7) % 7
      this.nextStatmentFormatted = new Date(date)
      this.nextStatmentFormatted.setDate(this.nextStatmentFormatted.getDate() + dayDelta)
      this.nextStatmentFormatted = this.changeFormate(this.nextStatmentFormatted)
      this.showNextDayStatementTransfer()
    },
    showNextDayStatementTransfer () {
      const date = new Date()
      const today = date.getDay()
      const paymentdays = []
      this.transferCoversprev.forEach((item, index) => {
        paymentdays[index] = item.day
      })
      paymentdays.sort()
      const realDaysArray = []
      paymentdays.forEach((item) => {
        const realItem = item === 1 ? 6 : item - 2
        realDaysArray.push(realItem)
      })
      let indexPaymentDayIndex = 0
      let isLastDay = false
      for (let i = realDaysArray.length - 1; i >= 0; i--) {
        if (today < realDaysArray[i]) {
          indexPaymentDayIndex = realDaysArray[i]
          this.nextTransferDay = realDaysArray[i]
          isLastDay = true
        } else if (!isLastDay) {
          indexPaymentDayIndex = realDaysArray[0]
          this.nextTransferDay = realDaysArray[0]
        }
      }
      let dayDelta = indexPaymentDayIndex - today
      dayDelta = (dayDelta + 7) % 7
      this.nextTransferDayFormatted = new Date(date)
      this.nextTransferDayFormatted.setDate(this.nextTransferDayFormatted.getDate() + dayDelta)
      this.nextTransferDayFormatted = this.changeFormate(this.nextTransferDayFormatted)
      this.daysLoader = false
    },
    changeFormate (oldFromat) {
      const today = oldFromat
      const yyyy = today.getFullYear()
      let mm = today.getMonth() + 1
      let dd = today.getDate()
      if (dd < 10) dd = `0${dd}`
      if (mm < 10) mm = `0${mm}`
      return `${dd}/${mm}/${yyyy}`
    },
    summaryCash () {
      sendRequest(true, false, this, 'api/v1/billing/statements/summary/', 'get', null, true,
        (response) => {
          this.summaryCashamount = response.data
        }
      )
    },
    loadFinancials () {
      this.tableLoader = true
      sendRequest(true, false, this, 'api/v1/analytics/merchant/dashboard/financial-center/', 'get', null, true, 
        (response) => {
          this.financials = response.data
          this.tableLoader = false
        }
      )
    },
    handleSearchDate (start, end) {
      this.flagFilters = []
      this.statuses = []
      this.fromDate = start ? new Date(start).toISOString().split('T')[0] : ''
      this.toDate = end ? new Date(end).toISOString().split('T')[0] : ''
      this.loadStatements()
    },
    loadStatmentsSearch () {
      if (this.searchInProgress || this.searchedValue === this.searchVal) {
        return
      }
      this.loadStatements()
    },
    handleSearch (search) {
      this.searchVal = search.target ? search.target.value : search
      return common.manageSearch(search, this.loadStatmentsSearch)
    }
  },
  components: {
    BankDeposits,
    ShipbluTable,
    ShipbluTr,
    ShipbluTh,
    ShipbluTd,
    LatestStatment,
    ShipbluPagination,
    DateFilter,
    InstantPayout,
    ShipbluCard
  },
  created () {
    this.activeTab = this.$route.query.subTab ? this.$route.query.subTab : 'statements'
    this.$router.push({
      query: {
        tab: this.$route.query.tab,
        page: this.$route.query.page,
        subTab: this.$route.query.subTab ? this.$route.query.subTab : 'statements'
      }
    }).catch(() => {})
    const merchantData = common.checkMerchantInLocalStorage(this)
    let userMerchant = {}
    merchantData.then(results => {
      this.merchant = results.merchantData
      this.offset = (this.currentPage - 1) * this.maximumItems
      this.merchantID = this.merchant.id
      this.billingDays = this.merchant.billing_days.sort()
      this.bankTransferDays = [...this.billingDays]
      userMerchant = results.userMerchantData
      if (userMerchant.access_financials) {
        this.loadStatements()
        this.loadFinancials()
        this.loadDay()
        this.transferDays()
        this.summaryCash()
      }
    })
  }
}
</script>

<style lang="scss">
@import "@/assets/scss/vuexy/apps/email.scss";
</style>