<template>
  <div>
    <shipblu-prompt
      class="shipment-modal breakdown-modal"
      @close="closeModal"
      :active.sync="breakdownModal"
      :title="$t('Available Cash Balance')"
      :buttons-hidden="true">
      <div class="grid grid-cols-2 mt-[16px]">
        <span class="text-black-wash">{{ $t('Cash Collection Balance') }}</span>
        <span class="text-right text-range-land"> + {{ financials.cash_collection_balance.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
        <span class="text-black-wash mt-[10px]">{{ $t('Service Fees') }}</span>
        <span class="text-right mt-[10px] text-fluorescent-red">- {{financials.service_fees_due.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2})}}</span>
        <span class="text-industrial-revolution text-[10px] col-span-2 mt-[18px]">{{ $t('AMOUNTS ON HOLD') }}</span>
        <span class="text-black-wash mt-[6px]">{{ $t('Service fees in progress') }}</span>
        <span class="text-right mt-[6px] text-fluorescent-red">- {{ financials.amounts_on_hold.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) }}</span>
        <span class="text-black-wash mt-[8px]">{{ $t('Refunds in progress') }}</span>
        <span class="text-right mt-[8px] text-fluorescent-red">- {{ financials.refunds_in_progress ? financials.refunds_in_progress.toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) : 0.00 }}</span>
        <hr class="col-span-2 my-[9px] border-0 bg-silver h-[0.5px]">
        <span class="text-black-wash">{{ $t('Available Cash Balance') }}</span>
        <span class="text-right">{{ availableCashBalance }}</span>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from './../../layouts/components/ShipBluPrompt.vue'

export default {
  props: ['breakdownModal', 'financials', 'availableCashBalance'],
  methods: {
    closeModal () {
      this.$emit('breakdownModal', false)
    }
  },
  components: {
    ShipbluPrompt
  }
}
</script>

<style>
.con-vs-dialog.shipment-modal.breakdown-modal .vs-dialog {
  max-width: 405px !important;
}
</style>