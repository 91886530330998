<template>
  <div class="mt-[26px]">
    <h2>{{ 'Transactions' }}</h2>
  </div>
</template>

<script>
export default {
  name: 'Transactions',
  data () {
    return {
      transactions: []
    }
  },
  created () {
    this.$router.push({
      query: {
        tab: this.$route.query.tab,
        page: 1
      }
    }).catch(() => {})
  }
}
</script>