<template>
  <div v-if="accessFinancials">
    <h2 class="pb-[20px] border-0 border-b border-solid border-clouded-vision">{{$t('Finances')}}</h2>
    <div class="flex mt-4">
      <div v-for="(tab, index) in tabs" :key="index" class="sm:w-auto w-1/2">
        <p @click="changeTab(tab.value)" :class="`font-medium whitespace-nowrap text-center md:text-[18px] text-[16px] sm:py-[11px] sm:px-[75px] px-[17px] p-3 rounded-t-[5px] cursor-pointer leading-[22px] ${tab.value === $route.query.tab ? 'text-primary border-0 border-b-1.5 border-solid border-primary bg-carte-blanche' : 'border-0 border-b border-solid border-steam text-industrial-revolution'}`">
          {{ $t(tab.name) }}
        </p>
      </div>
    </div>
    <OverViewFinancials v-if="activeTab === 'overview'" />
    <BillingFinancials v-else-if="activeTab === 'billing'" />
    <TransactionsFinancials v-else/>
  
    <!-- Successful Transaction Popup -->
    <div v-if="topUpTransaction.transaction_status === 'SUCCESSFUL'" class="top-up-method-container p-4 flex items-center justify-center">
      <div class="rounded-lg card w-full overflow-y-auto py-4 sm:px-6 px-4 vs-con-loading__container" id="top-up-loading">
        <div class="flex items-center justify-between mb-4">
          <p class="font-medium text-primary text-xl">{{$t('Debit/Credit Card Top-up')}}</p>
          <p @click="topUpTransaction = {}" class="material-symbols-outlined cursor-pointer text-2xl">close</p>
        </div>
        <div class="my-8">
          <p class="text-lg font-medium">
            <span>{{$t('Your request to deposit an amount of EGP')}}</span>
            <span class="text-primary">{{topUpTransaction.base_amount ? (' ' + Number(topUpTransaction.base_amount).toLocaleString('en', { minimumFractionDigits: 2, maximumFractionDigits: 2}) + ' ') : ' '}}</span>
            <span>{{$t('has been received and your account will be updated within 2 Business Days.')}}</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PayoutMethods from './PayoutMethods.vue'
import Invoices from './Invoices.vue'
import common from '../../assets/utils/common'
import BillingFinancials from './Billing.vue'
import SubAccounts from './SubAccounts.vue'
import OverViewFinancials from './OverViewFinancials.vue'
import TransactionsFinancials from './TransactionsFinancials.vue'

export default {
  data () {
    return {
      merchantStatus: '',
      topUpTransaction: {},
      accessFinancials: true,
      childMerchants: [],
      merchantID: '',
      routeQuery: {},
      tabs: [
        {
          name: 'OverviewTab',
          value: 'overview'
        },
        {
          name: 'Billing',
          value: 'billing'
        }
      ],
      activeTab: ''
    }
  },
  watch: {
    '$route.params.lang': {
      handler (newLang) {
        this.$router.push({
          name: this.$route.name, 
          params: { lang: newLang },
          query: {...this.routeQuery} 
        }).catch(() => {})
      }
    },
    '$route.query': {
      handler (newQuery) {
        this.routeQuery = {...newQuery}
      },
      immediate: true
    },
    '$route.query.tab' (val) {
      const query = val === 'billing' ? { ...this.routeQuery, subTab: 'statements', page: 1 } : { ...this.routeQuery }
      this.$router.push({ query: {...query} }).catch(() => {})
      this.changeTab(val)
    }
  },
  computed: {
    isSmallerScreen () {
      return this.$store.state.windowWidth < 768
    }
  },
  methods: {
    changeTab (tab) {
      this.activeTab = tab
      const query = tab === 'billing' ? { ...this.routeQuery, tab: this.activeTab, page: 1, subTab: this.$route.query.subTab ? this.$route.query.subTab : '' } : { ...this.routeQuery, tab: this.activeTab }
      this.$router.push({ query: {...query} }).catch(() => {})
    }
  },
  components: {
    PayoutMethods,
    Invoices,
    BillingFinancials,
    SubAccounts,
    OverViewFinancials,
    TransactionsFinancials
  },
  created () {
    this.activeTab = this.$route.query.tab
    this.$router.push({
      name: this.$route.name, 
      params: { lang: this.$route.params.lang },
      query: {...this.$route.query}
    }).catch(() => {})
  },
  mounted () {
    this.topUpTransaction = this.$route.query
    const merchantData = common.checkMerchantInLocalStorage(this)
    let userMerchant = {}
    let user = {}
    merchantData.then(results => {
      userMerchant = results.userMerchantData
      user = results.merchantData
      this.merchantID = user.id
      this.accessFinancials = userMerchant.access_financials
      if (this.accessFinancials === false) {
        this.$router.push({
          name: 'merchant-dashboard',
          query: { ...this.$route.query }
        }).catch(() => {})
      }
      this.merchantStatus = user.status
    })
  }
}
</script>
