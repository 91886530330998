<template>
  <div>
    <shipblu-prompt
      class="shipment-modal withdraw-modal"
      @close="closeModal"
      :active.sync="withdrawalRequestModal"
      :title="$t('Withdrawal request')"
      :buttons-hidden="true">
      <div class="mt-[32px]">
        <p class="text-young-night text-[16px] font-medium">{{ $t('Enter the amount you want to withdraw') }}</p>
        <shipblu-input labelBackground="white" autocomplete="nofill" v-validate="'required|decimal'" name="withdraw" v-model="requestedAmount" type="text" class="w-full mt-[16px]" @blur="validateCashCollections">
          <p class="slot-item text-industrial-revolution">{{ $t('EGP') }}</p>
        </shipblu-input>
        <p class="text-danger text-sm" v-show="errors.has('withdraw')">{{ $t('The withdrawal amount is required') }}</p>
        <p class="text-young-night text-[14px] mt-[12px]">{{ $t('Your transfer will be processed within 3 business days and subject to any applicable transfer fees') }}</p>
      </div>
      <div class="mt-[47px] grid grid-cols-2 gap-[12px]">
        <div @click="withdrawalRequestSend()" type="filled" class="cursor-pointer rounded-[4px] w-full text-[16px] py-[10px] px[94px] text-center" :class="requestedAmount ? 'bg-primary text-white' : 'bg-cold-morning text-industrial-revolution'">
          {{$t('Confirm')}}
        </div>
        <div @click="closeModal()" class="cursor-pointer bg-polished-metal rounded-[4px] text-white w-full text-[16px] py-[10px] px[94px] text-center" type="filled">
          {{$t('Cancel')}}
        </div>
      </div>
    </shipblu-prompt>
  </div>
</template>

<script>
import ShipbluPrompt from './../../layouts/components/ShipBluPrompt.vue'
import {sendRequest} from './../../http/axios/requestHelper.js'
import vSelect from 'vue-select'

export default {
  props: ['withdrawalRequestModal'],
  data () {
    return {
      requestedAmount: ''
    }
  },
  methods: {
    closeModal () {
      this.$validator.pause()
      this.requestedAmount = ''
      this.$emit('withdrawalRequestModal', false)
    },
    validateCashCollections () {
      if (this.requestedAmount === '') {
        return
      }
      const value = parseFloat(this.requestedAmount)
      if (isNaN(value)) {
        this.requestedAmount = (0).toFixed(2)
        
      } else {
        this.requestedAmount = value.toFixed(2)
      }
      this.$emit('customerData', this.customerData)
    },
    withdrawalRequestSend () {
      this.$validator.validateAll().then(result => {
        if (result) {
          sendRequest(false, false, this, 'api/v1/merchant-wallet-refunds/', 'post',  {requested_amount : this.requestedAmount}, true,
            () => {
              this.closeModal()
            }
          )
        }
      })
    }
  },
  watch: {
    withdrawalRequestModal (val) {
      if (val) {
        this.$validator.resume()
      }
    }
  },
  components: {
    ShipbluPrompt,
    vSelect
  }
}
</script>
<style>
.con-vs-dialog.shipment-modal.withdraw-modal .vs-dialog {
    max-width: 530px !important;
}
</style>